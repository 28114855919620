import { useLocation } from '@reach/router';
import { Container, Pagination } from 'components';
import { SEO } from 'components/SEO';
import { PageProps } from 'gatsby';
import { Product, SeoDescriptions } from 'models';
import { Breadcrumbs, setFitlerUrl } from 'modules/navigation';
import {
  DesktopCategoryPageFilters,
  filterByColor,
  filterByConsumption,
  filterByModel,
  filterByPcsPerSqMeter,
  filterByRoofPitch,
  filterByWallWidth,
  MobileCategoryPageFilters,
  ProductListing,
  sortAlphabetically,
  usePagination,
} from 'modules/products';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { listing } from 'styles';
import { createMarkup, localizeNavCategory, notEmpty } from 'utils';

interface Props {
  products: Product[];
  navCategory: 'Roof' | 'Wall' | 'Facade';
  categoryDescription?: SeoDescriptions;
  categoryMetaDescription?: string;
}

export interface CategoryFiltersState {
  models: {
    [key: string]: boolean;
  };
  consumptions: {
    [key: string]: boolean;
  };
  colors: {
    [key: string]: boolean;
  };
  minRoofPitch: {
    [key: string]: boolean;
  };
  wallWidths: {
    [key: string]: boolean;
  };
  piecesPerMsq: {
    [key: string]: boolean;
  };
}

const INITIAL_FILTER_STATE = {
  models: {},
  consumptions: {},
  colors: {},
  minRoofPitch: {},
  wallWidths: {},
  piecesPerMsq: {},
};

const CategoryPage: React.FC<PageProps<unknown, Props>> = ({
  pageContext: {
    products,
    navCategory,
    categoryDescription,
    categoryMetaDescription,
  },
}) => {
  const location = useLocation();
  const queryParse = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [state, setState] =
    useState<CategoryFiltersState>(INITIAL_FILTER_STATE);
  const [filteredProducts, setFilteredProducts] = useState([...products]);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  const {
    p: currentLocationPage = 1,
    model,
    utrosak,
    boja,
    nagib,
    debljinaZida,
    m2,
  } = queryParse;
  const { next, prev, jump, currentData, currentPage, maxPage } =
    usePagination<Product>(
      filteredProducts,
      itemsPerPage,
      Number(currentLocationPage),
    );

  const models = [
    ...new Set(products.map((product) => product?.commercial?.model)),
  ];
  const colors = [
    ...new Set(products.map((product) => product.commercial.corporateColor)),
  ].filter(notEmpty);
  const wallWidths = [
    ...new Set(products.map((product) => product?.sku?.dimensions?.width)),
  ]
    .filter(notEmpty)
    .sort((a, b) => a - b);
  const consumptions = [
    ...new Set(
      products.map(
        (product) =>
          product?.sku?.salesRelation?.piecesDry ||
          product?.sku?.salesRelation?.piecesMortar,
      ),
    ),
  ]
    .filter(notEmpty)
    .sort((a, b) => a - b);

  useEffect(() => {
    const filteredModelsProducts = filterByModel(state.models, products);

    const filteredProductsByPcsPerSqMeter = filterByPcsPerSqMeter(
      state.piecesPerMsq,
      filteredModelsProducts,
    );

    const filteredColorProducts = filterByColor(
      state.colors,
      filteredProductsByPcsPerSqMeter,
    );

    const filteredProductsByRoofPitch = filterByRoofPitch(
      state.minRoofPitch,
      filteredColorProducts,
    );

    const filteredWidthProducts = filterByWallWidth(
      state.wallWidths,
      filteredProductsByRoofPitch,
    );

    const filteredConsumptionProducts = filterByConsumption(
      state.consumptions,
      filteredWidthProducts,
    );

    const sortedProducts = sortAlphabetically<Product>(
      filteredConsumptionProducts,
    );

    setFilteredProducts(sortedProducts);
  }, [state, products, location.pathname]);

  useEffect(() => {
    if (utrosak) {
      const consumptionsString = utrosak as string;
      const consumptions = consumptionsString.split('-');

      consumptions.forEach((consumption: string) => {
        handleChange(consumption, true, 'consumptions');
      });
    }

    if (boja) {
      const colorString = boja as string;
      const colors = colorString.split('-');

      colors.forEach((color: string) => {
        handleChange(color, true, 'colors');
      });
    }

    if (nagib) {
      const pitchesString = nagib as string;
      const pitches = pitchesString.split('-');

      pitches.forEach((pitch: string) => {
        handleChange(pitch, true, 'minRoofPitch');
      });
    }

    if (model) {
      const modelsString = model as string;
      const models = modelsString.split('-');

      models.forEach((model: string) => {
        handleChange(model, true, 'models');
      });
    }

    if (m2) {
      const metersString = m2 as string;
      const meters = metersString.split('-');

      meters.forEach((meter: string) => {
        handleChange(meter, true, 'piecesPerMsq');
      });
    }

    if (debljinaZida) {
      const wallWidths = debljinaZida as string;
      const widths = wallWidths.split('-');

      widths.forEach((width: string) => {
        handleChange(width, true, 'wallWidths');
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boja, m2, model, nagib, utrosak, debljinaZida]);

  useEffect(() => {
    setFitlerUrl(state, currentPage);
  }, [
    currentPage,
    state,
    state.colors,
    state.consumptions,
    state.minRoofPitch,
    state.models,
    state.piecesPerMsq,
    state.wallWidths,
  ]);

  if (!products.length) return null;

  return (
    <Container>
      <SEO
        productName={getSEOTitle() + products[0].commercial.category}
        productDescription={categoryMetaDescription}
      />

      <Breadcrumbs
        navigationCategory={localizeNavCategory(
          products[0].commercial.navigationCategories.code,
        )}
        category={
          products[0].commercial.category
            ? `${products[0].commercial.category} line`
            : 'Terca'
        }
      />

      <div css={listing.flex}>
        <DesktopCategoryPageFilters
          products={products}
          navCategory={navCategory}
          models={models}
          colors={colors}
          wallWidths={wallWidths}
          consumptions={consumptions}
          handleChange={handleChange}
          clearFilters={clearFilters}
          filtersState={state}
        />

        <section css={listing.listSection}>
          <MobileCategoryPageFilters
            products={products}
            navCategory={navCategory}
            models={models}
            colors={colors}
            wallWidths={wallWidths}
            consumptions={consumptions}
            handleChange={handleChange}
            clearFilters={clearFilters}
            filtersState={state}
          />

          {categoryDescription && (
            <div css={listing.descriptionWrapper}>
              <h2 css={listing.pageTitle}>
                Porotherm {products[0].commercial.category}
              </h2>
              <div css={listing.descriptionContent}>
                <p
                  dangerouslySetInnerHTML={createMarkup(
                    categoryDescription.description,
                  )}
                  css={listing.pageDescription}
                />
                <ul css={listing.bulletsWrapper}>
                  {categoryDescription.bullets?.map((bullet) => (
                    <li key={bullet} css={listing.pageDescription}>
                      {bullet}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <ProductListing products={currentData} />

          {filteredProducts.length > 10 && (
            <Pagination
              currentPage={Number(currentLocationPage)}
              next={next}
              prev={prev}
              jump={jump}
              maxPage={maxPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
            />
          )}
        </section>
      </div>
    </Container>
  );

  function handleChange(name: string, value: boolean, section?: string) {
    if (!section) return;

    setState((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [name]: value,
      },
    }));
    jump(1);
  }

  function clearFilters() {
    setState(INITIAL_FILTER_STATE);
    jump(1);
  }

  function getSEOTitle() {
    switch (navCategory) {
      case 'Wall':
        return 'Porotherm ';
      case 'Facade':
        return 'Terca ';
      case 'Roof':
        return 'Tondach ';
      default:
        return '';
    }
  }
};

export default CategoryPage;
